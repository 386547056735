define([
    'lodash',
    'warmupUtils/pointers/pointerGeneratorsRegistry'
], function (
    _, pointerGeneratorsRegistry
) {
    'use strict';
    const type = 'general';

    pointerGeneratorsRegistry.registerPointerType(type, _.constant(null), _.constant(true));
    pointerGeneratorsRegistry.registerPointerType('metadata', _.constant(null), _.constant(true));
    const simpleGetterSchemas = {
        getSiteThemeDataItem: {id: 'theme', path: ['pagesData', 'masterPage', 'data', 'theme_data', 'THEME_DATA']},
        getOrphanPermanentDataNodes: {id: 'orphanPermanentDataNodes', path: ['orphanPermanentDataNodes']},
        getTextRuntimeOverallBorders: {id: 'overallBorders', path: ['textRuntimeLayout', 'overallBorders']},
        getDockedRuntimeLayout: {id: 'dockedRuntimeLayout', path: ['dockedRuntimeLayout']},
        getNoEnforceAnchorsPointer: {id: 'noEnforceAnchors', path: ['noEnforceAnchors']},
        getSaveInvalidationCount: {id: 'saveInvalidationCount', path: ['saveInvalidationCount']},
        getPagesData: {id: 'pagesData', path: ['pagesData']},
        getServiceTopology: {id: 'serviceTopology', path: ['serviceTopology']},
        getCommittedMobilePages: {id: 'committedMobilePages', path: ['committedMobilePages']},
        getDeletedPagesMapPointer: {id: 'deletedPagesMap', path: ['deletedPagesMap']},
        getUserId: {id: 'userId', path: ['rendererModel', 'userId']},
        getIsStudioUser: {id: 'isStudioUser', path: ['documentServicesModel', 'userInfo', 'isStudio']},
        hasSites: {id: 'hasSites', path: ['documentServicesModel', 'hasSites']},
        getEditorSessionId: {id: 'editorSessionId', path: ['documentServicesModel', 'editorSessionId']},
        getMobileStructuresPointer: {id: 'mobileStructures', path: ['mobileStructures']},
        getMobileConversionHeuristicStrategy: {id: 'mobileConversionHeuristicStrategy', path: ['mobileConversionHeuristicStrategy']},
        getCompsToUpdateAnchors: {id: 'compsToUpdateAnchors', path: ['compsToUpdateAnchors']},
        getRuntimePointer: {id: 'runtime', path: ['runtime']},
        getNeverSaved: {id: 'neverSaved', path: ['documentServicesModel', 'neverSaved']},
        getIsDraft: {id: 'isDraft', path: ['documentServicesModel', 'isDraft']},
        getPublicUrl: {id: 'publicUrl', path: ['documentServicesModel', 'publicUrl']},
        getPermissions: {id: 'permissions', path: ['documentServicesModel', 'permissionsInfo']},
        getForbiddenPageUriSEOs: {id: 'forbiddenPageUriSEOs', path: ['urlFormatModel', 'forbiddenPageUriSEOs']},
        getUrlFormat: {id: 'urlFormat', path: ['urlFormatModel', 'format']},
        getClientSpecMap: {id: 'clientSpecMap', path: ['rendererModel', 'clientSpecMap']},
        getAutosaveInfo: {id: 'autoSaveInfo', path: ['documentServicesModel', 'autoSaveInfo']},
        getSiteVersion: {id: 'siteVersion', path: ['documentServicesModel', 'version']},
        getMetaSiteId: {id: 'metaSiteId', path: ['rendererModel', 'metaSiteId']},
        getDocumentType: {id: 'documentType', path: ['rendererModel', 'siteInfo', 'documentType']},
        getActiveModes: {id: 'activeModes', path: ['activeModes']},
        getContactFormsMetaData: {id: 'contactformsMetaData', path: ['contactforms_metadata']},
        getRenderFlags: {id: 'renderFlags', path: ['renderFlags']},
        getGhostStructure: {id: 'ghostStructureData', path: ['ghostStructureData']},
        getRoutersPointer: {id: 'routers', path: ['routers']},
        getSantaBase: {id: 'santaBase', path: ['santaBase']},
        getDevSiteAppDefIdPointer: {id: 'devSiteAppDefId', path: ['devSiteAppDefId']},
        getCurrency: {id: 'currency', path: ['rendererModel', 'sitePropertiesInfo', 'currency']},
        getTimeZone: {id: 'timeZone', path: ['rendererModel', 'sitePropertiesInfo', 'timeZone']},
        getRegionalLanguage: {id: 'currency', path: ['rendererModel', 'sitePropertiesInfo', 'language']},
        getCommonConfig: {id: 'commonConfig', path: ['commonConfig']}
    };

    Object.freeze(simpleGetterSchemas);

    const getRenderRealtimeConfigKeyedItem = (getItemAt, cache, ...key) => cache.getPointer(`renderRealtimeConfig${key.join('_')}`, type, ['renderRealtimeConfig', ...key]);

    const complexGetters = {
        getAllStylesInPage(getItemAt, cache, pageId) {
            return cache.getPointer('theme', type, ['pagesData', pageId, 'data', 'theme_data']);
        },
        getRenderFlag(getItemAt, cache, flagName) {
            return cache.getPointer(`renderFlags${flagName}`, type, ['renderFlags', flagName]);
        },
        getRenderRealtimeConfigItem(getItemAt, cache, itemName) {
            return cache.getPointer(`renderRealtimeConfig${itemName}`, type, ['renderRealtimeConfig', itemName]);
        },
        getIsCompHiddenPointer(getItemAt, cache, compId, viewMode) {
            return getRenderRealtimeConfigKeyedItem(getItemAt, cache, 'compsToHide', viewMode, compId);
        },
        getAutoSaveInnerPointer(getItemAt, cache, key) {
            return cache.getPointer(`autoSaveInfo${key}`, type, ['documentServicesModel', 'autoSaveInfo', key]);
        },
        getPublishSaveInnerPointer(getItemAt, cache) {
            return cache.getPointer('publishSaveInitiator', type, ['documentServicesModel', 'publishSaveInitiator']);
        },
        getSilentSaveInnerPointer(getItemAt, cache) {
            return cache.getPointer('silentSaveInitiator', type, ['documentServicesModel', 'silentSaveInitiator']);
        },
        getMediaQualityItemPointer(getItemAt, cache, videoId) {
            return cache.getPointer(`mediaQualityStore${videoId}`, type, ['mediaQualityStore', videoId]);
        },
        getMediaPlaybackItemPointer(getItemAt, cache, playerId) {
            return cache.getPointer(`mediaStore${playerId}`, type, ['mediaStore', playerId]);
        },
        getSvgStorePointer(getItemAt, cache, svgId) {
            // Inner pointers: 'content', 'info', 'boxBoundaries', 'viewport'
            return cache.getPointer(`svgShapes${svgId}`, type, ['svgShapes', svgId]);
        },
        getClientSpecMapEntry(getItemAt, cache, applicationId) {
            return cache.getPointer(`clientSpecMap${applicationId}`, type, ['rendererModel', 'clientSpecMap', applicationId]);
        },
        getClientSpecMapEntryByAppDefId(getItemAt, cache, appDefinitionId) {
            const csm = getItemAt(['rendererModel', 'clientSpecMap']);
            const applicationId = _.get(_.find(csm, {appDefinitionId}), 'applicationId');
            return this.getClientSpecMapEntry(getItemAt, cache, applicationId);
        },
        getClientSpecMapEntriesByPredicate(getItemAt, cache, predicate) {
            const csm = getItemAt(['rendererModel', 'clientSpecMap']);
            const filteredCSM = predicate(csm);
            return _.map(filteredCSM, appData => this.getClientSpecMapEntry(getItemAt, cache, appData.applicationId));
        }
    };

    const getterFunctions = _(simpleGetterSchemas)
        .mapValues(function (schema) {
            return function (getItemAt, cache) {
                return cache.getPointer(schema.id, type, schema.path);
            };
        })
        .assign(complexGetters)
        .value();

    Object.freeze(getterFunctions);

    pointerGeneratorsRegistry.registerDataAccessPointersGenerator(type, getterFunctions);
});
